import { useState } from 'react'

import { IconButton } from '@/components/icon-button'
import { Text } from '@/components/text'
import { MobileNavigationItems } from './mobile-navigation-items'
import { isActiveChild, useIsActiveCategory } from '../../utils'
import { HeaderNavItem } from '../navigation-types'
import { Link } from '@/components/link/link'
import ChevronUpIcon from '@/components/icons/svg/chevron-up'
import { cn } from '@/common/utils/lib'

const getFontWeight = (level?: number) => {
  switch (level) {
    case 0:
      return 'bold'
    case 1:
      return 'semibold'
    default:
      return 'normal'
  }
}

const getFontColor = (level?: number) => {
  switch (level) {
    case 0:
    case 1:
      return 'black'
    default:
      return 'grey500'
  }
}

const getFontCase = (level?: number) => (level === 0 ? 'upper' : 'normal')

export const MobileNavigationItem = (item: HeaderNavItem) => {
  const { level, items, label, href } = item

  const { isAcitveCategoryByUrl, pathname } = useIsActiveCategory()
  const [isOpen, setIsOpen] = useState(() => isActiveChild(pathname, items))

  const hasChildren = !!items && items?.length > 0

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 justify-between pl-4 py-2">
        <Link href={href} className="hover:text-secondary" isExternal>
          <Text
            weight={getFontWeight(level)}
            color={
              isAcitveCategoryByUrl(pathname, href)
                ? 'red'
                : getFontColor(level)
            }
            case={getFontCase(level)}
          >
            {label}
          </Text>
        </Link>
        {hasChildren && (
          <IconButton
            className="h-5 mx-2"
            onClick={() => setIsOpen(!isOpen)}
            variant="ghost"
            size="sm"
          >
            <ChevronUpIcon
              className={cn(
                'transition-all duration-300',
                !isOpen && 'rotate-180',
              )}
            />
          </IconButton>
        )}
      </div>
      <div className={cn('flex flex-col pl-2', !isOpen && 'hidden')}>
        <MobileNavigationItems level={(level || 0) + 1} items={items} />
      </div>
    </div>
  )
}
