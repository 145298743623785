import { useTranslations } from 'next-intl'

import { cn } from '@/common/utils/lib'
import { LOGOUT_PATH, MY_ACCOUNT_PATH, MY_ORDERS_PATH } from '../../paths'
import { Link } from '@/components/link/link'

const MenuLink = ({
  href,
  onClick,
  className,
  children,
}: {
  href: string
  onClick?: () => void
  className?: string
  children: React.ReactNode
}) => (
  <Link
    href={href}
    onClick={onClick}
    className={cn(
      'flex h-12 px-2 w-full items-center hover:bg-grey-100',
      className,
    )}
    isExternal
  >
    {children}
  </Link>
)

export const CustomerPopupMenuItems = () => {
  const t = useTranslations('Header')

  return (
    <>
      <MenuLink href={MY_ACCOUNT_PATH}>{t('myAccount')}</MenuLink>
      <MenuLink href={MY_ORDERS_PATH}>{t('myOrders')}</MenuLink>
      <MenuLink href={LOGOUT_PATH} className="font-bold">
        {t('logOut')}
      </MenuLink>
    </>
  )
}
